.p-modal-btn {
  background-color: #065097 !important;
  border-color: #007bff;
  white-space: nowrap;
  margin-left: 0;
  margin: 0;
  border-radius: 6px;
}

.p-modal-btn:active {
  /* background-color: #d3e8fc !important;
     color: #065097 !important;
     border-color: #005cbf;
   */
  background-color: #850101 !important;
  color: #fff !important;
  border-color: #fff !important;
}

.p-modal-btn:hover {
  /* background-color: #d3e8fc !important; */
  background-color: #850101 !important;
  color: #fff !important;
  border-color: #fff !important;
}

.p-modal-btn:focus {
  /* background-color: #d3e8fc !important; */
  background-color: #850101 !important;
  color: #fff !important;
  border-color: #fff !important;
  /* box-shadow: 0 0 0 0.2rem rgba(6, 84, 167, 0.5) !important; */
  box-shadow: 0 0 0 0.2rem rgba(167, 6, 6, 0.5) !important;
}

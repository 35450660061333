.loading {
    color: #065097;
    font-weight: 300;
    font-size: 2em;
    text-align: center;
}

.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}
  
@keyframes dots {
    0%, 20% {
      color: #065097;
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    40% {
      color: #065097;
      text-shadow:
        .25em 0 0 rgba(0,0,0,0),
        .5em 0 0 rgba(0,0,0,0);}
    60% {
      text-shadow:
        .25em 0 0 #065097,
        .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
      text-shadow:
        .25em 0 0 #065097,
        .5em 0 0 #065097;
}}
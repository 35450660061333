Field {
  width: 25%;
}

label {
  display: block !important;
  text-align: left;
  font: 600 1rem/35px Montserrat;
  letter-spacing: 1.18px;
  color: #12426f;
  opacity: 1;
  white-space: nowrap;
}

.form-group {
  padding-left: 5px;
  padding-right: 5px;
}

.errors {
  color: red;
  margin-top: 5px;
}

.form-button-wrapper {
  padding-right: 45px !important;
}

.form-button-wrapper button {
  margin-right: -1px;
  margin-left: 13px;
}
.required-fields {
  text-align: right;
  font: 300 15px/23px Montserrat;
  letter-spacing: 1.24px;
  color: #21509e;
}

@media (max-width: 767px) {
  .form-group {
    margin-bottom: 0.5rem !important;
  }
}

@media (min-width: 767px) and (max-width: 1050px) {
  label {
    font: 600 0.9rem/35px Montserrat;
  }
}

@media (min-width: 767px) and (max-width: 1100px) {
  .form-control {
    font-size: 0.85rem !important;
  }
}

img.login-logo {
  max-width: 23rem !important;
  max-height: 5.25rem !important;
}

form {
  min-width: 150px;
}

#login-title {
  font-size: 2.5rem !important;
}

.copyright-wrapper {
  font-size: 1rem !important;
  white-space: nowrap;
}

.forgot {
  margin-top: 2px;
  white-space: nowrap;
  float: right;
}

.login-button {
  white-space: nowrap;
}

@media (max-width: 767px) {
  img.login-logo {
    max-width: 18rem !important;
  }
  .forgot {
    float: left;
  }
}

@media (max-width: 400px) {
  .copyright-wrapper {
    font-size: 0.8rem !important;
  }
}

.cis-actors-fields {
  overflow-y: auto;
  border-radius: 15px;
  width: 100%;
  background-color: white;
  padding: 15px;
  margin-bottom: 20px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
}

.cis-actors-fields > span {
  margin-left: 10px;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  font-family: Roboto;
  letter-spacing: 0.94px;
  color: #2474bf;
  opacity: 1;
  font-weight: 500;
}

.backdrop {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.patient-modal {
  position: fixed;
  background-color: white;
  border-radius: 12px;
  width: 100%;
  max-width: 70%;
  padding: 50px;
  z-index: 100;
  top: 45%;
  left: 50%;
  transform: translate(-40%, -40%);
  text-align: center;
}

@media (max-width: 1024px) {
  .patient-modal {
    top: 41%;
  }
}

@media (max-width: 767px) {
  .patient-modal {
    left: 42.5%;
  }
}

.no-p-data {
  color: #850101;
  margin: auto;
  font-size: 1.2rem;
}

.graph-sorting {
  display: flex;
}

.modal-btn {
  background-color: #850101 !important;
  color: #fff !important;
  border-color: #fff !important;
  white-space: nowrap;
  border-radius: 6px !important;
}

.modal-btn:active {
  background-color: #fff !important;
  color: #850101 !important;
  border-color: #850101 !important;
}

.modal-btn:hover {
  background-color: #fff !important;
  color: #850101 !important;
  border-color: #850101 !important;
}

.modal-btn:focus {
  background-color: #fff !important;
  color: #850101 !important;
  border-color: #850101 !important;
  box-shadow: 0 0 0 0.2rem rgba(167, 6, 6, 0.5) !important;
}

@media (max-width: 1024px) {
  .graph-sorting {
    display: block;
  }
}

.containerBox {
  padding-left: 3%;
  padding-right: 3%;
  margin-top: 15px;
  margin-bottom: 15px;
}

.menuBox {
  display: flex;
  justify-content: space-between;
}

.menuSub {
  flex: 1;
}

.userBox {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.bell {
  margin-right: 5px;
}

.adjuster {
  margin-top: -0.5%;
}

.ems-position {
  margin-right: 55px;
  font-size: 0.8rem;
  color: #045097;
  font-weight: 500;
}

#dropdown-action-user-org {
  margin-top: 0px !important;
}

/* notification bell with badge */
i {
  margin-right: 20px;
  text-align: center;
  vertical-align: middle;
  position: relative;
  color: #045097;
  font-size: 1.33333em;
}
.badge-custom:after {
  content: attr(notifications-counter);
  position: absolute;
  left: 0.6rem;
  background: rgb(255, 96, 96);
  height: 1.5rem;
  top: -0.5rem;
  right: 1.7rem;
  width: 1.5rem;
  text-align: center;
  line-height: 1.45rem;
  font-size: 0.6rem;
  border-radius: 100%;
  color: white;
  border: 1px solid rgb(255, 96, 96);
}
/* notification bell with badge */

@media (max-width: 769px) {
  .ems-position {
    margin-right: 43px;
  }  
}

@media (min-width: 769px) {
  #header-logo {
    margin-top: 15px !important;
    margin-bottom: 15px;
    max-width: 22%;
  }
  .user-dropdown {
    min-width: 200px;
  }
} ;
.paragraphs {
    text-align: left;
    font: normal normal normal 14px Roboto;
    letter-spacing: 1.06px;
    color: #343434;
    line-height: 1.6;
    text-align: justify;
}

.headers {
    text-align: left;
    font: normal normal medium 25px Roboto;
    letter-spacing: 1px;
    color: #065097;
    font-weight: bolder;
}

.boxes {
    background-color: white;
    border-radius: 12px;
    height: calc(100% - 1.5rem);
    text-align:justify
}

.file-names {
    color: #065097;
    cursor: pointer;
    font-weight: 500;
}

.sub-headers {
    text-align: left;
    font: normal normal medium 25px Roboto;
    letter-spacing: 1.18px;
    color: #343434;
    font-weight: bold;
}
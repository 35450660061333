.aligner {
  padding-right: 10px !important;
}

.dropdown-menu {
  width: 100%;
}

.dropdown-scroll {
  height: 400px;
  overflow-y: auto;
}

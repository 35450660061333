.dash-heading-refactor {
  color: #065097;
  font-weight: 600;
  font-size: 2.3rem;
  margin-top: 5px;
}

.dash-secondary-heading-refactor {
  font-size: 24px;
  color: #12426f;
  font-weight: 600;
  letter-spacing: 1px;
}

.dashboard-background-container {
  background-color: white;
  border-radius: 8px;
  padding: 12px;
}

.dashboard-register-button-refactor {
  background-color: #0b61a4 !important;
  color: #fff !important;
  border-radius: 8px !important;
}

.text-dashboard-refactor {
  text-align: left;
  font-family: "Roboto";
  font-size: 18px;
}

.dash-view-button-refactor {
  color: #0b61a4 !important;
  background-color: transparent !important;
  border-radius: 8px !important;
  border: none !important;
  font-weight: 500;
  font-size: 14px;
}

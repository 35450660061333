.table-header {
    color: #12426f !important;
    font-weight: 500;
}

.row-borders {
    border-radius: 10px;
}

.vertical-centered-cols {
    margin-top: auto;
    margin-bottom: auto;
}


.OfficialMap {
  height: 75vh;
}

.cis-top-box {
  width: 95%;
  margin-left: 1.5vw;
}

.flex-row-container {
  display: flex;
  justify-content: space-evenly;
}

.flex-kid {
  flex: 1;
}

.cis-box {
  padding-bottom: 2vh;
  margin-top: 1vh;
}

.cis-heading {
  padding-left: 1%;
}

.container-box {
  box-shadow: 0px 3px 10px #00000017;
  margin-top: 0.75vh;
  font-family: Poppins;
  padding-left: 0.75vw;
  border-radius: 20px;
  padding-right: 0.75vw;
  padding-top: 0.75vh;
  margin-bottom: 5vh;
  background-color: #fff;
  height: 580px;
  overflow: hidden;
}

.incident-box {
  padding-bottom: 5vh;
  background-color: #fff;
  height: 580px;
  overflow: auto;
}

.incidents {
  display: block;
  width: 100%;
  padding-left: 1.2vw;
}

.cis-heading {
  color: #065097;
  font-weight: 600;
  font-size: 1.5rem;
  margin-top: 5px;
}

.actors-fields {
  height: 480px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.coverBlank {
  padding-top: 34px;
}

.button-container-row {
  display: flex;
  flex-wrap: nowrap;
  margin-right: -15px;
  margin-left: -15px;
}

.button-box {
  margin-top: 1vh;
  margin-bottom: 1vh;
}

@media only screen and (max-width: 768px) {
  .cis-top-row {
    margin-top: 2vh;
  }
  .cis-Map-CIS {
    margin-left: 0.75vw;
  }
}

@media only screen and (max-width: 992px) {
  .lower-box {
    display: block;
  }
  .cis-box {
    padding-left: 2vw;
    padding-right: 4vw;
    width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .cis-box-ems {
    padding-left: 2vw;
    padding-right: 4vw;
    width: 100%;
  }
  .cis-top-box-ems {
    padding-right: 1.5vw;
  }
}

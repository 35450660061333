h2 {
  margin-left: 15px;
}

.font-awesome-icon {
  margin-right: 5px;
}

.flex-parent-element {
  display: flex;
  align-items: center;
}

.btn-container {
  margin-left: 15px;
  margin-right: 35px;
}

.new-patient-btn {
  background-color: #065097 !important;
  border-color: #007bff;
  white-space: nowrap;
  margin-left: 0;
  margin: 0;
}

.new-patient-btn:active {
  background-color: #d3e8fc !important;
  color: #065097 !important;
  border-color: #005cbf;
}

.new-patient-btn:hover {
  background-color: #d3e8fc !important;
  color: #065097 !important;
  border-color: #005cbf;
}

.new-patient-btn:focus {
  background-color: #d3e8fc !important;
  color: #065097 !important;
  border-color: #005cbf;
  box-shadow: 0 0 0 0.2rem rgba(6, 84, 167, 0.5) !important;
}

#triage-page {
  margin-top: 3% !important;
  margin-bottom: 3% !important;
  margin-left: 0px;
  margin-right: 30px;
}

@media (max-width: 767px) {
  #triage-page {
    margin-top: 5% !important;
    margin-bottom: 5% !important;
  }
}

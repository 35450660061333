.delete-warning {
  color: #850101;
  margin: auto;
  font-size: 1.4rem;
  font-weight: 600;
}

.btn-box {
  display: flex;
  justify-content: center;
}

.d-m-btn {
  width: 100%;
  max-width: 380px;
  margin: 20px;
  border-radius: 8px !important;
}

.delete-btn {
  background-color: #850101 !important;
  color: #fff !important;
  border-color: #fff !important;
}

.delete-btn:active {
  background-color: #fff !important;
  color: #850101 !important;
  border-color: #850101 !important;
}

.delete-btn:hover {
  background-color: #fff !important;
  color: #850101 !important;
  border-color: #850101 !important;
}

.delete-btn:focus {
  background-color: #fff !important;
  color: #850101 !important;
  border-color: #850101 !important;
  box-shadow: 0 0 0 0.2rem rgba(167, 6, 6, 0.5) !important;
}

.cancel-btn {
  background-color: #fff !important;
  color: #065097 !important;
  border-color: #065097 !important;
}

.cancel-btn:active {
  background-color: #065097 !important;
  color: #fff !important;
  border-color: #fff !important;
}

.cancel-btn:hover {
  background-color: #065097 !important;
  color: #fff !important;
  border-color: #fff !important;
}

.cancel-btn:focus {
  background-color: #065097 !important;
  color: #fff !important;
  border-color: #fff !important;
  box-shadow: 0 0 0 0.2rem rgba(6, 84, 167, 0.5) !important;
}

@media (min-width: 481px) and (max-width: 768px) {
  .delete-warning {
    font-size: 1.2rem;
  }
}

@media (max-width: 560px) {
  .btn-box {
    display: block;
  }
  .d-m-btn {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .delete-warning {
    font-size: 1rem;
  }
}

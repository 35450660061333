.patientHr {
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.tableBox {
  box-shadow: 0px 3px 10px #00000017;
  margin-top: 0.75vh;
  font-family: Poppins;
  padding-left: 0.75vw;
  border-radius: 20px;
  padding-right: 0.75vw;
  padding-top: 0.75vh;
  margin-bottom: 5vh;
}

.tableStyle {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
}

.tableContainer {
  overflow: auto;
}

#patientTable {
  border-collapse: separate;
  border-spacing: 0 3vh;
}

.tableH {
  color: #343434;
  font-size: 1.1rem;
  font-family: Montserrat;
  font-weight: 600;
  cursor: grab;
  white-space: nowrap;
  padding: 0 15px;
}

.tableData {
  white-space: nowrap;
}

.tableData td {
  padding: 0 15px;
}

.paginationFlexContainer {
  display: flex;
  padding: 0.5vh 1vw;
  align-items: baseline;
  justify-content: space-between;
}

.showInfo {
  margin: 0 3%;
}

.pageControl {
  margin: 0 3% 0 2%;
}

.patientPagination {
  font-size: 0.8rem;
  font-weight: 400;
}

.selectNumber {
  background-color: #fff;
  border: none;
  text-align: right;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 0.8rem;
  margin-right: 24px;
}

.pageButton {
  border: none;
  background-color: none;
}

.pageButton:hover {
  border: none;
  background-color: rgba(rgb(58, 53, 53), rgb(38, 39, 38), rgb(32, 32, 34), alpha);
}

@media (max-width: 480px) {
  .paginationBox {
    width: 100%;
    display: block;
  }
}

@media (min-width: 500px) {
  .flexContainer {
    display: flex;
    padding: 0.5vh 1vw;
    align-items: baseline;
  }

  .flexChild {
    flex: 1;
  }

  .showInfo {
    margin: 0 0 0 1%;
  }

  .pageControl {
    margin: 0;
  }
}

.row-borders-events {
    border-radius: 10px;
}

.vertical-centered-cols-events {
    margin-top: auto;
    margin-bottom: auto;
}

.font-events {
    font-size: 13px;
}
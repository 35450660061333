.dropdown button {
    width: 100%;
    margin: 0;
    color:#fff;
}

.btn-primary.dropdown-toggle {
    background-color: #065097!important;
}

.btn-primary.dropdown-toggle:hover {
    background-color: #d3e8fc!important;
    color: #065097!important;
}

.btn-primary.dropdown-toggle:focus {
    background-color: #065097!important;
    color:#fff!important;
}

.btn-primary:not([disabled]):not(.disabled):active, .btn-primary:not([disabled]):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    background-color: #d3e8fc!important;
    color: #065097!important;
}

.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(6, 84, 167, 0.5) !important;
}

.custom-btn {
    background-color: #065097!important;
    color: #fff
}

.custom-btn :hover {
    background-color: #d3e8fc!important;
    color: #065097!important;
}

.custom-btn :focus {
    box-shadow: 0 0 0px 0rem rgb(38 143 255 / 50%) !important;
    color:#fff;
}
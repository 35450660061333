#patients-chart canvas.chartjs-render-monitor {
  position: relative;
  top: 0;
}

#patients-chart {
  width: 50%;
}

@media (max-width: 1024px) {
  #patients-chart {
    width: 100%;
  }
}
